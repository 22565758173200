$color-black-dark: #070706;
$color-black-medium: #1C1C1A;
$color-black-light: #242424;

$color-white-eggshell: #FAF2E9;

$color-gold-primary: #ffbf00;

.about {
 p {
    text-align: left;
    padding: 5rem 3rem 0;
 }
}

