// @import url('https://fonts.googleapis.com/css2?family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

$color-black-dark: #070706;
$color-black-medium: #1C1C1A;
$color-black-light: #242424;

$color-white-eggshell: #FAF2E9;

$color-gold-primary: #ffbf00;

@font-face {
    font-family: "The Seasons";
    src: url(./fonts/The-Seasons/The-Seasons-Light.ttf)
    format("truetype");
}

@font-face {
    font-family: "The Italic Seasons";
    src: url(./fonts/The-Seasons/The-Seasons-Light-Italic.ttf)
    format("truetype");
}


  *, *::after, *::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }
  
  
  html {
    /* font-size: 10px; */
    font-size: 62.5%;
  }
  
  body {
    box-sizing: border-box;
    color: $color-white-eggshell;
    background-color: $color-black-medium;
  }

h1 {
    font-family: The Italic Seasons, Arial, Helvetica, sans-serif;
    font-size: 8rem;
    letter-spacing: 1.5rem;
    font-weight: 200;
    font-style: italic;

    @media(max-width:1200px) {
      flex-basis: 100%;
      
     }
     @media(max-width:800px) {
      font-size: 9vw;
      
     }
}

h2 {
    font-family: The Seasons, Arial, Helvetica, sans-serif;
    font-size: 4rem;
    font-weight: 200;
    text-transform: uppercase;
    padding: 5rem 0;

    @media(max-width:800px) {
      font-size: 6vw;
      
     }
}

h3 {
    font-family: The Seasons, Arial, Helvetica, sans-serif;
    font-size: 2.6rem;
    font-weight: 200;

    @media(max-width:800px) {
      font-size: 4vw;
      
     }
}

p {
    font-family:Montserrat, Arial, Helvetica, sans-serif;
    font-size: 1.6rem;
    line-height: 1.3;
    letter-spacing: 1px;
    font-weight: 300;
}

a {
  color: $color-white-eggshell;
}

button {
    width: fit-content;
    background-color: rgba($color-black-dark, .4);
    color: $color-white-eggshell;
    font-family:Montserrat, Arial, Helvetica, sans-serif;
    border: $color-white-eggshell solid 2px;
    box-shadow: .5rem 1rem 2rem rgba($color-white-eggshell, .3);
    border-radius: 2rem;
    padding: .6rem 2rem .6rem;
    transition: all .3s;
    &:hover {
        cursor: pointer;
        animation-name: Grow;
        animation-duration: .3s;
        transform: scale(1.1);

    }

}
  
  .App {
    // border: $color-gold-primary solid 1px;
    
    max-width: 1550px;
    width: 99vw;
    margin: 0 auto;
    min-width: 30rem;
    // display: grid;
    // place-content: center;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .section {
    text-align: center;
    padding: 5rem 0 0;
    border-bottom: $color-gold-primary solid 1px;
    box-shadow: .3rem 0rem 2rem rgba($color-gold-primary, .25);

}

  .Header {
    width: 100%;
    background-image: linear-gradient(to right bottom, rgba($color-black-dark, .2),
    rgba($color-black-dark, .8), rgba($color-gold-primary, .2)),
     url(../../public/images/piano-background.jpg);
   background-size: cover;
   background-position: center center;
  min-height: 70rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 3rem;
    position: relative;
       
    .title {
      display: flex;
      flex-wrap: wrap;
      
     
    }

        ul {
            list-style: none;
            
        }

        &__portrait {
            width: 20rem;
            aspect-ratio: 1;
            border-radius: 100%;
            margin: 0rem;
        }

        &__blurb-container {
            max-width: 45rem;
            display: flex;
            flex-direction: column;
            
            .button-container {
                display: grid;
                place-content: center;
                padding: 1rem 0;
            }  


        }

  }

 

.trifold {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    // padding: 3rem;
 
   

    h3 {
        text-transform: uppercase;
    }
    img {
        width: 30rem;
        border-radius: 5px;
        padding: 0;
        margin: 1rem;
        box-shadow: 0 .5rem 1.8rem rgba($color-white-eggshell, .25);
        transition: all .2s;
      
        @media(max-width:500px) {
          width: 20rem;
         }

        &:hover {
            transform: scale(1.05) translateY(-5px);
            cursor: pointer;
        }

    }
    p {
      width: 30rem;
    }



    &__score {

      
        padding: 1rem 1rem;
        * {
            padding: .8rem;
          }
         
        } 
}


.footer {
  padding-bottom: 5rem;
}



  @keyframes moveInRight {
    0% {
      opacity: 0;
      transform: translateX(10rem);
    }
    50% {
      
      transform: translateX(-1rem);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes Grow {
    0% {
      transform: scale(1);
    }
    750% {
        transform: scale(1.5);
      }
    100% {
        transform: scale(1.1);
    }
  }

  