.footer {
   

p {
    
}

//     &__logo--box {
//         // width: 6rem;
//         position: relative;
//         height: 8.5rem;
//         aspect-ratio: 9/16;
//         background-color: none;
//       }

//       &__text--box {

//         display: grid;
//         grid-template-columns: 40% 40%;
//         justify-content: center;
//         grid-gap: 15rem;
//        padding: 5rem;
//         @media (max-width: 1000px) {
//             grid-template-columns: 100%;
//             padding: 3rem 0rem;
//       }
//     }
// }

// .footer-text { padding-top: 1rem;
//     border-top: 2px solid $color-grey;
//     color: $color-white;
//     font-size: 1rem;
// }

// .socials {
   
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: space-between;

//     &-link {
// &, &:link, &:visited {
//     text-transform: uppercase;
//     font-size: 1rem;
//     color: $color-white;
//     text-decoration: none;
//     border-bottom: none;

// }

        

//     }
// }

.legal {
font-size: 1.2rem;

}
}